import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/pages/pages-state';
import { LocationState } from 'app/pages/pages-state/location/location.reducer';
import { selectCurrentLocation } from 'app/pages/pages-state/location/location.selectors';
import { APP_BASE, EPOS_URL } from 'app/shared/constants/urls';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { UserService } from 'app/shared/services/user.service';
import { selectCurrentUrl } from 'app/store/router/custom-route-serializer';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { Location } from '../../../shared/interfaces/location';
import { FeatureToggle } from 'app/shared/interfaces/feature-toggle/feature-toggle-types';
import { OrdrService } from 'app/shared/services/ordr.service';
import { StorageService } from '../../../shared/services/StorageService';
import { QuickSwitchControllerService } from 'app/shared/ordr-service-api/api/quickSwitchController.service';
import { environment } from 'environments/environment';
import { initializePendo } from 'app/shared/helpers/pendo-helpers';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly = false;
    location: Location;
    appHost: string;
    eposUrl: string;
    isRunningInIOSApp = false;
    private localStorageSubscription: Subscription;
    private currentLocationSub: Subscription;
    private currentUrlSub: Subscription;
    authenticatedUser: AuthenticatedUser;
    public locationId: number;

    public eposFeature: FeatureToggle;

    showLocationSpecificHeaderFeatures = false;

    themes = [
        {
            value: 'default',
            name: 'Light'
        },
        {
            value: 'dark',
            name: 'Dark'
        }
    ];

    currentTheme = 'default';

    menuItems: Array<NbMenuItem> = [];

    pinCodeFeatureEnabled: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sidebarService: NbSidebarService,
        private themeService: NbThemeService,
        private userService: UserService,
        private locationStore: Store<LocationState>,
        private breakpointService: NbMediaBreakpointsService,
        private analytics: AnalyticsService,
        private menuService: NbMenuService,
        private appStore: Store<AppState>,
        private ordrService: OrdrService,
        private storageService: StorageService,
        private quickSwitchControllerService: QuickSwitchControllerService
    ) {
        this.appHost = APP_BASE || 'https://favrit.com';
        this.eposUrl = EPOS_URL;
    }

    public ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService
            .onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

        this.themeService
            .onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$)
            )
            .subscribe(themeName => (this.currentTheme = themeName));

        this.subscribeToCurrentLocation();
        this.subscribeToCurrentUrl();

        this.loadAuthenticatedUser();

        this.isRunningInIOSApp = localStorage.getItem('ios_app_version') !== null;
        this.localStorageSubscription = this.storageService
            .monitorKey('ios_app_version', localStorage)
            .subscribe(event => {
                this.isRunningInIOSApp = event.newValue !== null;
            });
    }

    loadAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(authenticatedUser => {
                this.authenticatedUser = authenticatedUser;
                this.addAppLink();
                this.configureAnalytics();
            });
    }

    loadQuickSwitchFeature() {
        if (!this.location) {
            return;
        }

        this.quickSwitchControllerService
            .isFeatureEnabled(this.location.id)
            .toPromise()
            .then(toggle => {
                this.pinCodeFeatureEnabled = toggle.enabled;
                this.addAppLink();
                this.configureAnalytics();
            });
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();

        this.currentLocationSub?.unsubscribe();
        this.currentUrlSub?.unsubscribe();
        this.localStorageSubscription?.unsubscribe();
    }

    subscribeToCurrentUrl() {
        this.currentUrlSub = this.appStore.select(selectCurrentUrl).subscribe(url => {
            this.showLocationSpecificHeaderFeatures = !url.startsWith('/location/internal');
        });
    }

    public changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    public toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');

        return false;
    }

    public goToOpeningHours() {
        if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.params.locationId) {
            this.router.navigate([
                'location',
                this.route.snapshot.firstChild && this.route.snapshot.firstChild.params.locationId,
                'opening-hours'
            ]);
        }
    }

    public navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    public subscribeToCurrentLocation() {
        this.currentLocationSub = this.locationStore.select(selectCurrentLocation).subscribe(currentLocation => {
            // We also want to set the location to undefined if there is no current location set.
            // Therefore we don't use the if-statement where we check if the location is present in
            // this component, like we do in most other places we subscribe to the current locaiton.
            this.location = currentLocation;
            this.loadQuickSwitchFeature();
            this.addAppLink();
            this.configureAnalytics();
        });
    }

    private configureAnalytics() {
        if (this.authenticatedUser) {
            initializePendo(this.authenticatedUser, this.location?.locationGroupId);
            this.analytics.identifyUser(this.authenticatedUser, this.location);
        }
    }

    private addAppLink(): void {
        this.menuItems = [];

        if (this.authenticatedUser) {
            this.menuItems = [{ title: 'Log out', link: '/auth/logout' }, ...this.menuItems];
        }

        if (this.location?.publicId) {
            this.menuItems = [
                {
                    title: 'Open app',
                    url: `${this.appHost}/menu/location/${this.location.publicId}`,
                    target: '_blank'
                },
                ...this.menuItems
            ];
        }

        if (this.showLocationSpecificHeaderFeatures && this.location?.id && !this.isRunningInIOSApp) {
            this.ordrService.getEposFeatureState(this.location.id).subscribe(x => {
                this.eposFeature = x;

                const eposAppIsAdded = this.menuItems.some(item => item.title === 'Open ePOS app');
                if (x.active && !eposAppIsAdded) {
                    this.menuItems = [
                        {
                            title: 'Open ePOS app',
                            url: `${this.eposUrl}/${this.location.publicId}`,
                            target: '_blank'
                        },
                        ...this.menuItems
                    ];
                }
            });
        }

        if (this.location && this.showLocationSpecificHeaderFeatures && !this.isRunningInIOSApp) {
            this.menuItems = [{ title: 'Change location', link: `/location/locations` }, ...this.menuItems];
        }

        if (this.location?.id && this.authenticatedUser && this.pinCodeFeatureEnabled) {
            if (environment.environment !== 'prod') {
                this.menuItems = [
                    {
                        title: 'Change user',
                        link: '/auth/logout',
                        queryParams: { type: 'CHANGE_USER', location_id: this.location.id }
                    },
                    ...this.menuItems.filter(x => x.title !== 'Change user')
                ];
            }
        }
    }
}
